import React from 'react';
import { Helmet } from 'react-helmet';
import iconPhoto from '../static/images/marlena-icon.jpg';

const App = () => (
  <div id="App">
    <Helmet>
      <meta charSet="utf-8" />
      <title>Marlena López Hilderley</title>
      <link rel="canonical" href="https://marlenalopezhilderley.com" />
      <link rel="icon" href={iconPhoto} />
    </Helmet>
    <h1>Site under construction</h1>
    {/* <Nav />
    <Home /> */}
    {/* <News /> */}
    {/* <Resume />
    <Media />
    <Contact /> */}
  </div>
);

export default App;
